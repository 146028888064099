<template>
  <div class="row">
    <div class="col-9">
      <h5 class="pl-1 font-weight-bold my-2">Vaccine</h5>
    </div>
    <div class="col-12">
      <medical-nav></medical-nav>
    </div>
    <div class="col-12">
      <table class="table table-sm table-bordered small">
        <thead>
          <tr>
            <td class="align-middle text-center font-weight-bold">Vaccine Brand</td>
            <td class="align-middle text-center font-weight-bold">Inoculated Date</td>
            <td class="align-middle text-center font-weight-bold" style="width:30%;">Completion Date (2nd Dose)Completion Date (2nd Dose)</td>
            <td class="align-middle text-center font-weight-bold">Administering Country</td>
            <td class="align-middle text-center font-weight-bold">Action</td>
          </tr>
        </thead>
        <tbody>
          <template v-if="crewDocuments">
            <tr v-for="vaccine in crewDocuments">
              <td class="text-center">{{vaccine.vaccineBrand ? vaccine.vaccineBrand.toUpperCase() : ''}}</td>
              <td class="text-center">{{formatEliteDate(vaccine.inoculate_date)}}</td>
              <td class="text-center">{{formatEliteDate(vaccine.completion_date)}}</td>
              <td class="text-center">{{vaccine.country}}</td>
              <td class="text-center">
                <button type="button" class="btn btn-xs btn-secondary"
                        data-target="#vaccine"
                        data-toggle="modal"
                        @click="viewVaccineFile(vaccine)">View</button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="vaccine" tabindex="-1" aria-labelledby="vaccine" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style="height:auto">
            <div class="modal-header">
              <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW VACCINE</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-5">
                  <table class="w-100">
                    <tr>
                      <td>Vaccine Brand:</td>
                      <td class="font-weight-bold">{{vaccine.vaccineBrand ?  vaccine.vaccineBrand.toUpperCase() : ''}}</td>
                    </tr>
                    <tr>
                      <td>Inoculate Date:</td>
                      <td class="font-weight-bold">{{formatEliteDate(vaccine.inoculate_date)}}</td>
                    </tr>
                    <tr>
                      <td>Dose 2 Required for this Brand?</td>
                      <td class="font-weight-bold">{{vaccine.dose_2 ? 'YES' : 'NO'}}</td>
                    </tr>
                    <tr>
                      <td>Completion Date (2nd Dose):</td>
                      <td class="font-weight-bold">{{formatEliteDate(vaccine.completion_date)}}</td>
                    </tr>
                    <tr>
                      <td>Is this Booster Shot? :</td>
                      <td class="font-weight-bold">{{vaccine.is_booster ? 'YES' : 'NO'}}</td>
                    </tr>
                    <tr>
                      <td>Administering Country:</td>
                      <td class="font-weight-bold">{{vaccine.country ? vaccine.country.toUpperCase() : ''}}</td>
                    </tr>
                  </table>
                  <hr>
                </div>
                <div class="col-7" style="height: 77vh">
                  <template v-if="vaccineFile">
                    <iframe style="height: inherit; width: 100%" :src="vaccineFile"></iframe>
                  </template>
                  <div v-else class="alert alert-danger">
                    NO FILE FOUND
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MedicalNav from "@/components/crew/document/medical/MedicalNav";
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";


export default {
  name: "Vaccine",
  components:{MedicalNav},
  data(){
    return{
      vaccineFile:'',
      vaccine:{}
    }

  },
  methods:{
    ...mapActions(['getCrewVaccine']),
    formatEliteDate(date){
      return DateService.dateEliteFormat(date);
    },

    async viewVaccineFile(vaccine){
      this.vaccineFile=false;
      this.vaccine=Object.assign({},vaccine);
      const crewDocumentFile= await CrewService.getCrewVaccineFileFromCMS({covidVaccine: vaccine.id});
      this.vaccineFile=crewDocumentFile ? FileService.base64FileToBlobUrl(crewDocumentFile.file) : false;
    }
  },
  computed:{
    ...mapGetters(['crewDocuments'])
  },
  async created() {
    const params= {
      cms_id:this.$route.params.id
    }
    await this.getCrewVaccine(params)
  }


}
</script>

<style scoped>

</style>
